.container__footer {
  display: flex;
  justify-content: center;
  margin: 80px 0px 30px 0px;
}
.container__item__link {
  text-decoration: none;
  color: #202020;
}
.container__footer__link {
  display: flex;
  justify-content: center;
  padding: 3rem;
  border-top: 1px solid #dbdbdb;
}
.item__footer {
  width: 300px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1em;
}
.item__footer__link {
  color: #202020;
  font-size: 14px;
  text-decoration: none;
}
