
.screen__container{
  /*margin: auto 10px;*/
  /* max-width: 1280px; */
}
.screen__children{
  padding: 1rem;
}


@media (min-width: 900px) {
  .screen__container__404 {
    display: flex;
  }
}
.item__footer__link__404{
  background-color: #eb1c2d;
  color: #fff;
  padding: 0.5em 1em;
  font-size: 1rem;
  text-decoration: none;
}