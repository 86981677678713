.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  padding: 4px 0px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.container__text {
  margin: 0px 6px;
  font-size: 12px;
}

.container__button {
  border: none;
  font-size: 12px;
  background: none;
  color: #055effd4;
  margin-top: 2px;
}