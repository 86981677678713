
.screen__container{
  /*margin: auto 10px;*/
  /* max-width: 1280px; */
}
@media (min-width: 300px) {
  .screen__container {
    max-width: 100%;
  }
}

@media (min-width: 900px) {
  .screen__container {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
}

.screen__children{
  padding: 1em;
}